import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import { Helmet } from "react-helmet"



export default function Homepage(props) {
  const { homepage } = props.data

  return (
    <Layout {...homepage}>
      {homepage.blocks.map((block) => {
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        return <Component key={id} {...componentProps} />
      })}
       <Helmet>
          <meta charSet="utf-8" />
          <title>aiKATE | Your Solution For IT, IoT, Business</title>
          <link rel="canonical" href="https://aikate.eu" />
          <meta name="Your Solution For IT" content="Software development, IoT solutions" />
          <meta name="description" content="We are a Croatian software development company with the goal and ambition to make people's life easier by providing smart tech solutions." />
          <meta name="google-site-verification" content="mHAu5tprqPAOMauBOJyw0I-irJOez0qJCWC26QPGGVk"/>
        </Helmet>
    </Layout>
  )
}

export const query = graphql`
  {
    homepage {
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...HomepageFeatureListContent
        ...HomepageCtaContent
        ...HomepageTestimonialListContent
        ...HomepageBenefitListContent
        ...HomepageStatListContent
        ...HomepageProductListContent
      }
    }
  }
`
